import { customElement, html, LitElement } from "lit-element";
// css styles
// @ts-ignore
import mainCSS from "../assets/css/main.css";
// @ts-ignore
import normalizeCSS from "../assets/css/normalize.css";

// @ts-ignore
import bridgeLogo from "../assets/img/logo/logo.png";
// @ts-ignore
import checkEmailSvg from "../assets/img/icon/check-email.svg";
// @ts-ignore
import signInSuccessSvg from "../assets/img/icon/sign-in-successful.svg";
// @ts-ignore
import signingInSvg from "../assets/img/icon/browser-lock.svg";
// @ts-ignore
import signInExpiredSvg from "../assets/img/icon/error.svg";
// @ts-ignore
import cardSvg from "../assets/img/icon/card.svg";
// @ts-ignore
import bankSvg from "../assets/img/icon/bank.svg";
// @ts-ignore
import kycUserWaitSvg from "../assets/img/icon/kyc-user-wait.svg";

@customElement("icon-bridge-widget")
export class IconBridgeWidget extends LitElement {

  static styles = [
    mainCSS,
    normalizeCSS
  ];

    render() {
        // @ts-ignore
        return html`
            <div id="icon-bridge-widget-wrapper" style="margin:0; padding: 0; height: 100vh; width: 100vw; dis">

                          <div class="grid-cell">
                            <div class="p-30 text-center">
                              <img
                                  src=${bridgeLogo}
                                  class="logo margin-bottom-15"
                              />
                            </div>
                            <div class="p-30 conclusion-text-container">
                              <p class="text-center margin-bottom-15 label-small">
                              We regret to inform you that Bridge Wallet will be closing its service soon.
                              </p>
                              <p class="label-small text-center margin-bottom-15">
                              Please retrieve your private key 
                                <a target="_blank" href="https://reveal.magic.link/icon/bridge" class="link">Here</a> <br/>
                                and use 
                                <a target="_blank" href="https://chrome.google.com/webstore/detail/hana-wallet/jfdlamikmbghhapbgfoogdffldioobgl" class="link">Hana wallet</a>
                                 to access your assets.
                              </p>
                              <p class="text-center" style="font-family:montserrat-regular">© Bridge ${new Date().getFullYear()}, All rights reserved.</p>
                            </div>
                          </div>
            </div>
            <!-- ### End of modal code ### -->
        `;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        "icon-bridge-widget": IconBridgeWidget;
    }
}
